@import '../../styles/customMediaQueries.css';

.root {

}

.wrapper {
  max-width: 1450px;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.container {
  position: relative;
}

.heading {
  color: var(--marketplaceColor);
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  z-index: 3;
  width: 100%;
  @media (--viewportLarge) {
    font-size: 100px;
    top: 60px;
    transform: translateX(-45%);
  }
}


.image {
  width: 100%;
  height: calc(40vh);
  object-fit: cover;
  @media (--viewportLarge) {
    height: calc(100vh - 114px);
  }
}

.line {
  @media (--viewportLarge) {
    height: 100%;
    width: 20px;
    background-color: var(--matterColorBright);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 2;
  }
}

.link {
  display: block;
  margin: 30px;
  @media (--viewportLarge) {
    position: absolute;
    bottom: 50px;
    transition: all 0.3s;
    z-index: 2;
    right: 10vw;
  }
}

.link:first-of-type {
  @media (--viewportLarge) {
    left: 10vw;
    right: auto;
  }
}

.button {
  width: 100%;
  background-color: var(--marketplaceColor);
  border-radius: 10px;
  border: transparent;
  padding: 15px;
  font-size: 20px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transitionStyle);
  @media (--viewportLarge) {
    padding: 20px;
    font-size: 38px;
    color: var(--marketplaceColor);
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.span {
  font-size: 10px;
  display: block;
  font-weight: 400;
  @media (--viewportLarge) {
    font-size: 14px;
  }
}